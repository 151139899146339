import { useEffect, useState } from 'react';
import { useNAASAvailable } from '@containers/Subscription/hooks/useNAASAvailable';
import { useSignContract } from '@containers/Subscription/hooks/useSignContract';
import { useContractInformationV2 } from '@containers/Subscription/hooks/v2/useContractInformationV2';
import { useCheckUnleashFlag } from '@shared/hooks/permissions/unleash/useCheckUnleashFlag';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function useContracts() {
  const navigate = useNavigate();

  const checkFlag = useCheckUnleashFlag();

  const [searchParams] = useSearchParams();
  const sourceModule = searchParams.get('module');

  useTrackingScreen('expenses_contract_screen', { module: sourceModule });

  const { signExpenseContracts, isLoading: isSigning } = useSignContract(sourceModule);

  const [shouldCreateContract, setShouldCreateContract] = useState(!!sourceModule);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const naasAvailibility = useNAASAvailable();

  const contractV2 = useContractInformationV2({
    allowSelfAcquisition: shouldCreateContract || !!sourceModule,
  });

  const { corporateCardContract, saasServiceContract, travelContract, isLoading } = contractV2;

  function onContractCreationRequest() {
    if (contractV2.isPending) {
      setShouldCreateContract(true);
    }
  }

  useEffect(() => {
    // never redirect if it's not a self acquisition
    if (!contractV2.isSelfAcquisition) return;

    const isPlatformAcquisition = checkFlag('FLASH_OS_OFFERS_RECOMMENDATION_SECTION');

    if (!isPlatformAcquisition || naasAvailibility?.isLoading) return;

    const isLegacySAASRedirectEnabled = checkFlag('FLASHOS_EXPENSE_REDIRECT_LEGACY_CONTRACT');

    setIsRedirecting(true);

    // change acquisition plan according to new saas availability
    const planId = naasAvailibility?.data?.available
      ? 'sales_flash_expense_basic_v202501'
      : 'sales_flash_expense_basic';

    // should not redirect if legacy saas flag disabled
    if (!isLegacySAASRedirectEnabled && planId === 'sales_flash_expense_basic') {
      return;
    }

    navigate(`/acquisition/overview?id=${planId}`);
  }, [contractV2.isSelfAcquisition, checkFlag, navigate, naasAvailibility]);

  const isFullPageLoaderEnabled = isLoading || naasAvailibility?.isLoading || isRedirecting;

  return {
    contracts: contractV2.contracts,
    signExpenseContracts,
    isSigning,
    saasServiceContract,
    corporateCardContract,
    travelContract,
    onContractCreationRequest,
    isCreatingContracts: contractV2.isPending,
    isLoading: isLoading,
    isFullPageLoaderEnabled,
    isSelfAcquisition: contractV2.isSelfAcquisition,
    isNewSAAS: naasAvailibility.data?.available || false,
  };
}
