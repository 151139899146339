import { useContext, useMemo } from 'react';
import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';
import { useCheckActiveModule } from '@flash-tecnologia/expense-web-activation-utils';
import { TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useAnalyticsPermissions } from '@shared/hooks/permissions/systemPermissions/useAnalyticsPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AnalyticsAdvancesSection } from '../AnalyticsSections/AnalyticsAdvancesSection/AnalyticsReportsSection';
import { AnalyticsCorporateCardSection } from '../AnalyticsSections/AnalyticsCorporateCardSection/AnalyticsCorporateCardSection';
import { AnalyticsExpenseSection } from '../AnalyticsSections/AnalyticsExpenseSection/AnalyticsExpenseSection';
import { AnalyticsLogsSection } from '../AnalyticsSections/AnalyticsLogsSection/AnalyticsReportsSection';
import { AnalyticsReimbursementsSection } from '../AnalyticsSections/AnalyticsReimbursementsSection/AnalyticsReimbursementsSection';
import { AnalyticsReportsSection } from '../AnalyticsSections/AnalyticsReportsSection/AnalyticsReportsSection';
import AnalyticsCorporateCardSectionLabel from './components/AnalyticsCorporateCardSectionLabel/AnalyticsCorporateCardSectionLabel';
import * as SC from './styled';

export const AnalyticsPagePreview = () => {
  const t = useTranslate('analytics.sections');
  const { isDisabledExpense } = useContext(SessionContext);
  const isExpenseV2 = useCheckActiveModule('lifecycle');

  const { sections } = useAnalyticsPermissions();
  const {
    expenses: allowedExpenses,
    reports: allowedReports,
    corporateCard: allowedCorporateCard,
    advances: allowedAdvances,
    logs: allowedLogs,
  } = sections;

  const expenseEnableTabItems = useMemo(() => {
    const tabs = [
      {
        component: <AnalyticsExpenseSection key={'expense-section-page-view'} />,
        label: t(`expenses.title`),
        disabled: !allowedExpenses,
      },
      {
        component: <AnalyticsReportsSection key={'report-section-page-view'} />,
        label: t(`reports.title`),
        disabled: !allowedReports,
      },
      {
        component: <AnalyticsCorporateCardSection key={'corporate-card-page-view'} expensesVersion="v1" />,
        label: <AnalyticsCorporateCardSectionLabel />,
        disabled: !allowedCorporateCard,
      },
      {
        component: <AnalyticsLogsSection key={'logs-page-view'} />,
        label: t('logs.title'),
        disabled: !allowedLogs,
      },
    ];

    if (allowedAdvances) {
      tabs.push({
        component: <AnalyticsAdvancesSection key={'advances-page-view'} />,
        label: t(`advances.title`),
        disabled: !allowedAdvances,
      });
    }

    return tabs;
  }, [sections.toString()]);

  const expenseDisabledTabItems = [
    {
      component: <AnalyticsCorporateCardSection key={'corporate-card-page-view'} expensesVersion="v1" />,
      label: <AnalyticsCorporateCardSectionLabel />,
      disabled: !allowedCorporateCard,
    },
  ];

  const expenseV2TabItems = [
    {
      component: <AnalyticsCorporateCardSection key={'corporate-card-page-view'} expensesVersion="v2" />,
      label: <AnalyticsCorporateCardSectionLabel />,
      disabled: !allowedCorporateCard,
    },
    {
      component: <AnalyticsReimbursementsSection key={'corporate-card-page-view'} />,
      label: t(`reimbursements.title`),
      disabled: false,
    },
  ];

  let tabItens: TabItens[];
  if (isExpenseV2) {
    tabItens = expenseV2TabItems;
  } else if (isDisabledExpense) {
    tabItens = expenseDisabledTabItems;
  } else {
    tabItens = expenseEnableTabItems;
  }

  return (
    <SC.Container>
      <ModuleErrorBoundary
        moduleName="Cartão Corporativo"
        title="Erro ao tentar exibir banner de novidades do cartão corporativo">
        <external.ExternalRoot>
          <external.ReportNewsBanner />
        </external.ExternalRoot>
      </ModuleErrorBoundary>
      <SC.TabsInline variant="primary" defaultTab={0} tabItens={tabItens} />
    </SC.Container>
  );
};
