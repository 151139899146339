import * as y from 'yup';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const completionRegistrationSchema = y.object({
  contracts: y.array(y.object({ id: y.string() })).optional(),
  expense: y.object({
    expectedValue: y.number().required(),
    expectedStarting: y.number().optional(),
    adminManager: y.object({
      currentUser: y.boolean().required(),
      email: y.string().when('currentUser', currentUser => {
        return currentUser[0] || currentUser[0] === undefined
          ? y.string().nullable()
          : y.string().required().email().matches(EMAIL_REGEX);
      }),
    }),
  }),
  travel: y.object({
    expectedValue: y.number().required(),
  }),
});

export type CompletionRegistrationSchema = y.InferType<typeof completionRegistrationSchema>;
