import { useContext, useEffect, useState } from 'react';
import { getAccessTokenPayloadSync, getPermissionsFlags, IPermissionsFlags } from '@flash-tecnologia/hros-web-utility';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

export function useCheckUnleashFlag() {
  const { companyId } = useContext(SessionContext);
  const [flags, setFlags] = useState<IPermissionsFlags['flags']>([]);
  const getUnleashToggle = async () => {
    const { flags } = await getPermissionsFlags(companyId, '', getAccessTokenPayloadSync().economicGroupId);
    setFlags(flags || []);
  };

  function checkFlag(flagName: string): boolean {
    return flags?.some(_flag => _flag.name === flagName) || false;
  }

  useEffect(() => {
    companyId && getUnleashToggle();
  }, [companyId]);

  return checkFlag;
}
