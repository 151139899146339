import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

export const Caption = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]}!important;
  font-weight: 600 !important;
`;

export const WeekdayName = styled(Typography)`
  font-weight: 400 !important;
`;

export const LabelDay = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]}!important;
`;

export const DatePicker = styled(DayPicker)`
  font-family: var(--font-family-secondary);

  /* Remove margem do contêiner rdp */
  .rdp {
    margin: 0;
  }

  /* Estilo do cabeçalho do calendário (exibe mês e ano, ex: JAN 2023) */
  .date-range-picker--header {
    color: var(--color-neutral-light5);
    font-weight: 600;
  }

  /* Nome dos dias da semana (ex: D S T Q Q S S) */
  .date-range-picker--weekday-name {
    font-weight: 400;
  }

  /* Número dentro de cada célula de dia */
  .date-range-picker--day {
    font-weight: bold;
  }

  /* Número dentro das células de fim de semana */
  .date-range-picker--day-weekend {
    color: var(--color-neutral-light4);
    font-weight: bold;
  }

  /* Cor do início e fim do intervalo de seleção */
  .rdp-day_range_start,
  .rdp-day_range_end {
    & > p {
      color: white !important;
    }
  }

  /* Área de input de texto (para selecionar intervalo de datas) */
  .date-range-picker--input-area {
    margin: 5px;
    .date-range-picker--input-label {
      color: var(--color-neutral-light5);
    }
    .date-range-picker--inputs {
      display: flex;
      align-items: center;
      gap: 1rem;
      input {
        /* Remove o ícone do calendário do input de texto */
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
          display: none;
          -webkit-appearance: none;
        }
        width: 6rem;
        &:focus {
          outline: none;
        }
        color: var(--color-neutral-dark3);
        border-bottom: 2px solid var(--color-neutral-dark3);
      }
    }
  }

  /* Adiciona sombra e espaço entre o cabeçalho dos dias da semana e o corpo da tabela */
  .rdp-head {
    box-shadow: 0px 5px 4px -2px #0000001a;
    &:before {
      line-height: 1em;
      content: '\200C';
      display: block;
    }
  }

  /* Corpo da tabela (dias) */
  .rdp-tbody {
    &:before {
      line-height: 1em;
      content: '\200C';
      display: block;
    }
  }

  /* Estilo do intervalo selecionado */
  .rdp:not([dir='rtl']) {
    .rdp-cell:has(.rdp-day_range_start:not(.rdp-day_range_end)) {
      background-color: var(--color-secondary-light2) !important;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      padding: 3px;

      .rdp-day_range_start {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .rdp-cell:has(.rdp-day_range_end:not(.rdp-day_range_start)) {
      background-color: var(--color-secondary-light2) !important;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      padding: 3px;

      .rdp-day_range_end {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .rdp-day_range_middle > p {
      color: var(--color-secondary-dark2);
    }
  }

  /* Área do rodapé com os botões */
  .date-range-picker--footer-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
