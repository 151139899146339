import { TUserSettings } from '@containers/Expenses/context/types/userSettings';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useUserSettings() {
  const { data, error, isLoading } = useFetch<TUserSettings>(`v1/settings/user`);

  return {
    data,
    isLoading,
    error,
  };
}
