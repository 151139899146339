import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';

type Props = {
  expensesVersion: 'v1' | 'v2';
};

export function AnalyticsCorporateCardSection(props: Props) {
  return (
    <ModuleErrorBoundary
      moduleName="Cartão Corporativo"
      title="Erro ao tentar acessar os Insights e reports do cartão corporativo Flash">
      <external.ExternalRoot>
        <external.ReportSection session="corp-card" expensesVersion={props.expensesVersion} />
      </external.ExternalRoot>
    </ModuleErrorBoundary>
  );
}
