import { gql } from '@apollo/client';

export const GetResolvedKmPoliciesQuery = gql`
  query GetResolvedKmPolicies($projectId: Int) {
    getResolvedKmPolicies(projectId: $projectId) {
      allowOriginDestination
      allowOdometer
      allowGPS
      allowGPSPin
      allowMultiplePlaces
      allowSavedEstablishments
      allowSearchNewPlaces
    }
  }
`;
