import { useAnalyticsPermissions } from '@shared/hooks/permissions/systemPermissions/useAnalyticsPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AnalyticsAdvancesSection } from '../../../AnalyticsSections/AnalyticsAdvancesSection/AnalyticsReportsSection';
import { AnalyticsExpenseSection } from '../../../AnalyticsSections/AnalyticsExpenseSection/AnalyticsExpenseSection';
import { AnalyticsReportsSection } from '../../../AnalyticsSections/AnalyticsReportsSection/AnalyticsReportsSection';
import { TabsInline } from '../../styled';

export function LegacySection() {
  const t = useTranslate('analytics.sections');
  const { sections } = useAnalyticsPermissions();
  const { expenses: allowedExpenses, reports: allowedReports, advances: allowedAdvances } = sections;
  const tabs = [
    {
      component: <AnalyticsExpenseSection key={'expense-section-page-view'} />,
      label: t(`expenses.title`),
      disabled: !allowedExpenses,
    },
    {
      component: <AnalyticsReportsSection key={'report-section-page-view'} />,
      label: t(`reports.title`),
      disabled: !allowedReports,
    },
  ];

  if (allowedAdvances) {
    tabs.push({
      component: <AnalyticsAdvancesSection key={'advances-page-view'} />,
      label: t(`advances.title`),
      disabled: !allowedAdvances,
    });
  }
  return <TabsInline variant="secondary" defaultTab={0} tabItens={tabs} />;
}
