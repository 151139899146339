import { useContext } from 'react';
import { useCheckActiveModule } from '@flash-tecnologia/expense-web-activation-utils';
import { useTranslation } from '@locale/Translator';
import { fetcherMutation } from '@services/utils/fetcherClient';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { showToast } from '@shared/helpers/toast';
import useSWRMutation from 'swr/mutation';

import { ServiceException } from './error.type';
import { HttpStatus } from './status.type';
import { useHeaderDelegateOptions } from './useHeaderDelegateOptions';

type DefaultOptions = {
  disableDefaultErrors?: HttpStatus[];
};

interface IOptionsFetcherMutation extends RequestInit {
  delegationAllowed?: boolean;
}

// ignoring any because it will break a lot of places
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMutate = <TData = any>(
  path: string,
  onCustomError?,
  defaultPath?: boolean,
  options?: DefaultOptions,
) => {
  const { t } = useTranslation();
  const hasLegacyData = useCheckActiveModule('legacy-data');
  const { companyId, accessToken } = useContext(SessionContext);
  const defaultError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const onErrorBase = (error: ServiceException, key, configuration) => {
    if (error?.httpCode === HttpStatus.FORBIDDEN && !options?.disableDefaultErrors.includes(HttpStatus.FORBIDDEN)) {
      showToast({
        message: t('general.services.feedback.error_403'),
        type: 'error',
      });
    } else {
      onCustomError ? onCustomError(error, key, configuration) : defaultError(error, key);
    }
  };
  const { getOptions } = useHeaderDelegateOptions();

  const _fetcherMutation = (
    url: string,
    data: { arg: { path?: string; parameters?: { key: string; value: string }[]; options: IOptionsFetcherMutation } },
  ): Promise<TData> => {
    return fetcherMutation(
      url,
      {
        arg: {
          ...data.arg,
          options: {
            ...data?.arg?.options,
            headers: {
              ...getOptions(data?.arg?.options?.headers, data?.arg?.options?.delegationAllowed),
              ...(hasLegacyData ? { 'access-legacy-data': true } : {}),
            },
          },
        },
      },
      {
        companyId,
        accessToken,
      },
      defaultPath,
    );
  };

  const mutation = useSWRMutation(path, _fetcherMutation, {
    onError: onErrorBase,
  });

  return {
    mutation,
  };
};
