import { ChangeEvent, useEffect, useState } from 'react';
import { CheckboxList } from '@atoms/CheckboxList';
import {
  SearchField,
  SliderField,
  TagDateFilter,
  TagFilter,
  TagSliderRangeFilter,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useDebounce } from 'use-debounce';

import { DatePickerRange } from './DatePickerRange/DatePickerRange';

export type Variant = 'disabled' | 'primary' | 'secondary' | 'tertiary' | 'error';

export interface OptionsFilterList {
  label: string;
  value: string;
}

export enum FilterType {
  MULTIPLE = 'multiple',
  BOOLEAN = 'boolean',
  LIST = 'list',
  PERIOD = 'period',
  RANGE = 'range',
  SEARCH = 'search',
  CURRENCY_RANGE = 'currency_range',
}

export interface DateRange {
  from: Date;
  to?: Date;
}

export interface IFilterDate {
  endDate?: string;
  startDate?: string;
}

export interface FilterFactoryProps {
  key?: string;
  type: FilterType;
  label: string;
  variant?: Variant;
  options?: OptionsFilterList[];
  selectedOptions?: string[];
  badgeNumber?: string;
  onClick(value: string[] | DateRange | IFilterDate | null | number[]): void;
  onChange(value: string[] | DateRange | IFilterDate | null | number[] | ChangeEvent | string): void;
  onSubmit?(value: string[] | DateRange | IFilterDate | null | number[]): void;
  multiple?: boolean;
  min?: number;
  max?: number;
  fieldMask?(value: number): string;
  isTagFilter?: boolean;
  dateRange?: DateRange;
  value?: string[] | DateRange | null | number[] | string;
  isDisabled?: boolean;
}

export const FilterFactory = ({
  type,
  options,
  selectedOptions,
  onClick,
  onChange,
  min,
  max,
  fieldMask,
  label,
  variant = 'secondary',
  isTagFilter = false,
  dateRange,
  value,
  isDisabled = false,
}: FilterFactoryProps) => {
  const { getValueWithCurrency } = useValueFormatter();
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [searchValue] = useDebounce(filterSearchValue, 250);

  useEffect(() => {
    type === FilterType.SEARCH && onChange(searchValue);
  }, [searchValue]);

  const filter = () => {
    const filters = {
      [FilterType.MULTIPLE]: isTagFilter ? (
        <TagFilter
          hasLeftIcon={false}
          options={options}
          filterLabel={label}
          onApply={(value: string[] | number[]) => onClick(value)}
          onClear={() => onClick([])}
          variant={variant}
          optionIconType={'checkbox'}
          selectedOptions={selectedOptions}
          value={selectedOptions}
          disabled={isDisabled}
        />
      ) : (
        <CheckboxList options={options} onApply={onClick} selectedOptions={selectedOptions} multiple disableFooter />
      ),
      [FilterType.LIST]: isTagFilter ? (
        <TagFilter
          hasLeftIcon={false}
          options={options}
          filterLabel={label}
          onApply={(value: string[] | number[]) => onClick(value)}
          onClear={() => onClick([])}
          variant={variant}
          optionIconType="radio"
          selectedOptions={selectedOptions}
          value={selectedOptions}
          disabled={isDisabled}
        />
      ) : (
        <CheckboxList options={options} onApply={onClick} selectedOptions={selectedOptions} disableFooter />
      ),
      [FilterType.BOOLEAN]: isTagFilter ? (
        <TagFilter
          hasLeftIcon={false}
          options={options}
          filterLabel={label}
          onApply={(value: string[] | number[]) => onClick(value)}
          variant={variant}
          optionIconType={'checkbox'}
          selectedOptions={selectedOptions}
          value={selectedOptions}
          disabled={isDisabled}
        />
      ) : (
        <CheckboxList
          options={options}
          onApply={onClick}
          selectedOptions={selectedOptions}
          multiple={false}
          disableFooter
          disableSearch
        />
      ),
      [FilterType.PERIOD]: isTagFilter ? (
        <TagDateFilter
          hasLeftIcon={false}
          filterLabel={label}
          variant={variant}
          onSubmit={onClick}
          initialDateRange={dateRange}
          disabled={isDisabled}
        />
      ) : (
        <DatePickerRange
          className="datePickerFilter"
          onSelect={data =>
            onChange({ startDate: data?.from ? String(data?.from) : null, endDate: data?.to ? String(data?.to) : null })
          }
          selected={dateRange}
        />
      ),
      [FilterType.RANGE]: isTagFilter ? (
        <TagSliderRangeFilter
          className="SliderFilter"
          variant={variant}
          step={10}
          handler={onClick}
          min={min}
          max={max}
          fieldMask={fieldMask}
          filterLabel={label}
          valueLabelDisplay={'on'}
          disabled={isDisabled}
        />
      ) : (
        <SliderField
          className="SliderFilter"
          step={10}
          onChange={(_, values) => onChange(values as number[])}
          min={min}
          max={max}
          fieldMask={fieldMask}
        />
      ),
      [FilterType.SEARCH]: (
        <SearchField className="SliderFilter" onChange={e => setFilterSearchValue(e.target.value)} value={value} />
      ),
      [FilterType.CURRENCY_RANGE]: isTagFilter ? (
        <TagSliderRangeFilter
          variant={variant}
          step={10}
          handler={data => onChange(data)}
          min={min}
          max={max}
          filterLabel={label}
          fieldMask={value => getValueWithCurrency({ value })}
          onApply={data => onClick(data as number[])}
          valueLabelDisplay={'on'}
          initialValues={value as number[]}
          disabled={isDisabled}
        />
      ) : (
        <SliderField
          type="range"
          step={10}
          onChange={(_, values) => onChange(values as number[])}
          min={min}
          max={max}
          fieldMask={value => getValueWithCurrency({ value })}
          value={value as number[]}
          valueLabelDisplay={'on'}
        />
      ),
    };
    return filters[type];
  };
  return filter() || <></>;
};
