import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';

export function AnalyticsReimbursementsSection() {
  return (
    <ModuleErrorBoundary moduleName="Reembolsos" title="Erro ao tentar acessar os Insights e reports de Reembolsos">
      <external.ExternalRoot>
        <external.ReportSection session="expenses" expensesVersion="v2" />
      </external.ExternalRoot>
    </ModuleErrorBoundary>
  );
}
