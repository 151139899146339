import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { GetResolvedKmPoliciesQuery } from '../context/queries/kmPolicies';
import { IGetResolvedKmPolicies } from '../context/types/kmPolicies';
import { useUserSettings } from './dataSource/useUserSettings';

interface IUseResolvedKmPolicies {
  allowOriginDestination: boolean;
  allowOdometer: boolean;
  allowMultiplePlaces: boolean;
  allowSavedEstablishments: boolean;
  allowSearchNewPlaces: boolean;
  error: unknown;
  loading: boolean;
}

export const useKmPolicies = (): IUseResolvedKmPolicies => {
  const { data: userSettings, isLoading: userSettingsIsLoading, error: userSettingsError } = useUserSettings();
  const { loading, data, error } = useQuery<IGetResolvedKmPolicies, { projectId?: number }>(
    GetResolvedKmPoliciesQuery,
    {
      variables: {
        projectId: userSettings?.defaultProjectId || undefined,
      },
      skip: userSettingsIsLoading,
    },
  );

  return {
    ...data?.getResolvedKmPolicies,
    loading: userSettingsIsLoading || loading,
    error: error || userSettingsError,
  };
};
