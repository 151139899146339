import { useContext } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

type NaasAvailableResponse = {
  available: boolean;
  reason: string;
};

export function useNAASAvailable() {
  const { economicGroupId, companyId, employeeId } = useContext(SessionContext);

  const params = qs.stringify({ economicGroupId, companyId, employeeId });

  return useFetch<NaasAvailableResponse>(`v1/contracts/naas/available?${params}`, '', undefined, undefined, () => {});
}
