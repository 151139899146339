import { useEffect, useState } from 'react';
import ptbr from 'date-fns/locale/pt-BR';
import { DateFormatter, DateRange, DayPickerRangeProps, SelectRangeEventHandler } from 'react-day-picker';
import { useTheme } from 'styled-components';

import * as SC from './styled';

const MONTHS = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'] as const;

const WEEKDAYS = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'] as const;

/** Formats the displayed month and year */
const formatCaption: DateFormatter = date => (
  <SC.Caption variant="body4">{`${MONTHS[date.getMonth()]} ${date.getFullYear()}`}</SC.Caption>
);

/** Formats the head-row of weekdays */
const formatWeekdayName: DateFormatter = date => (
  <SC.WeekdayName variant="body3">{WEEKDAYS[date.getDay()]}</SC.WeekdayName>
);

/** Formats the text of each day-cell */
const formatDay: DateFormatter = date => <SC.LabelDay variant="body3"> {date.getDate()} </SC.LabelDay>;

export function DatePickerRange(props: Omit<DayPickerRangeProps, 'mode'> & { onSelect(data: DateRange) }) {
  const theme = useTheme();
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(props.selected);

  const handleSelect: SelectRangeEventHandler = range => {
    setSelectedRange(range);
    props.onSelect(range);
  };

  useEffect(() => setSelectedRange(props.selected), [props.selected]);
  return (
    <SC.DatePicker
      mode="range"
      selected={selectedRange}
      onSelect={handleSelect}
      locale={ptbr}
      formatters={{
        formatCaption,
        formatWeekdayName,
        formatDay,
      }}
      modifiersStyles={{
        range_start: {
          backgroundColor: theme.colors.primary,
        },
        range_middle: {
          backgroundColor: theme.colors.secondary[95],
        },
        range_end: {
          backgroundColor: theme.colors.primary,
        },
      }}
    />
  );
}
