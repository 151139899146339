import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export type TAssignmentForm = {
  expense: {
    expectedValue: number;
    expectedStarting?: number;
    adminManager: {
      currentUser: boolean;
      email?: string;
    };
  };
  travel: {
    expectedValue: number;
  };
};

type TSubmitReportResponse = {
  body: TAssignmentForm;
};

export function useSubmitAssignmentForm() {
  const t = useTranslate('molecules.acceptSubscriptionCheckbox.feedbacks.success');
  const { mutation } = useMutate<TSubmitReportResponse>('v1/contracts/deal/onboarding/hint');

  async function submit(body: TAssignmentForm) {
    try {
      const result = await mutation.trigger({
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...body,
            expense: {
              ...body.expense,
              expectedStarting: body.expense.expectedStarting === 0 ? undefined : body.expense.expectedStarting,
            },
          }),
        },
      });

      return result;
    } catch (error) {
      showToast({
        message: t('caption.stepTwo.error'),
        type: 'error',
      });
    }
  }

  return { submit, isLoading: mutation.isMutating, error: mutation.error, data: mutation.data };
}
